body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #fff !important;
}

.chq-atc--button {
  padding: 0 !important;
}

.chq-atc--button:hover {
  background-color: transparent !important;
}

.chq-atc--dropdown {
  top: -198px;
}
